import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Link = ({ label, href, target = '_blank', ...props }) => (
  <LinkRoot {...props}>
    <a target={target} rel="noopener noreferrer" href={href}>
      {label}
    </a>
    <LinkArrow />
  </LinkRoot>
);

Link.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

const LinkArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 17L19 12L14 7"
      stroke="#0068FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9191 12.0351L3.68803 12.0351"
      stroke="#0068FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LinkRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & a {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  & svg {
    backface-visibility: hidden;
    transition: transform 0.1s ease-in-out;
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }

  &:hover {
    cursor: pointer;
    & * {
      stroke: ${({ theme }) => theme.color.nbPrimary};
      color: ${({ theme }) => theme.color.nbPrimary};
    }
  }

  @media ${props => props.theme.breakpoints.md} {
    justify-content: center;
  }
`;

export { Link };
