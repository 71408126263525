import React from 'react';

const EthereumLogo = () => (
  <svg className="ethereumLogo" viewBox="0 0 82 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.5168 0.400879L0.000305176 67.6355L40.5168 49.2203V0.400879Z" fill="white" />
    <path d="M40.5165 49.2202L4.57764e-05 67.6354L40.5165 91.591V49.2202Z" fill="#D0D0D0" />
    <path d="M81.0404 67.6355L40.5164 0.400879V49.2203L81.0404 67.6355Z" fill="#D0D0D0" />
    <path d="M40.5164 91.591L81.0404 67.6354L40.5164 49.2202V91.591Z" fill="#B7B7B7" />
    <path d="M4.57764e-05 75.323L40.5165 132.423V99.2635L4.57764e-05 75.323Z" fill="white" />
    <path d="M40.5164 99.2635V132.423L81.0628 75.323L40.5164 99.2635Z" fill="#D0D0D0" />
  </svg>
);

export default EthereumLogo;