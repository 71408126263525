import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import React from 'react';

import { Large, Regular } from '../components/text';
import { navColorOptions } from '../components/navbar/navColors';
import { navSections } from '../components/navbar';
import { theme } from '../theme';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  Background,
  HeroTitle,
  HeroSubtitle,
  LinkCta,
  CoolVisual,
  SectionHeading,
  GarnishedInfoText,
  WhyWhnsContent,
  HowItWorksContent,
  TutorialLink,
} from '../components/WrappedHnsLandingPage';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

export const wrappedHnsPageQuery = graphql`
  query WrappedHnsPageQuery {
    copy: contentfulWrappedHnsLandingPage {
      title
      subtitle
      titleCtaHref
      titleCtaText
      whyWhnsTilte
      whyWhns1
      whyWhns2
      learnMoreCtaText
      learnMoreCtaHref
      howItWorksTitle
      howItWorksReceiving {
        json
      }
      receivingTutorialHref
      howItWorksWrapping {
        json
      }
      howItWorksUnwrapping {
        json
      }
      unwrappingTutorialHref
      faqHref
    }
  }
`;

const WrappedHnsPage = ({ data: { seo, copy } }) => {
  const meta = usePageMetaAttributes('wrappedHns');

  return (
    <Layout navColor={navColorOptions.burnt} currentSection={navSections.PRODUCTS}>
      <SEO {...meta} />
      <Background color="burntUmber">
        <div css={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <HeroTitle>{copy.title}</HeroTitle>
          <HeroSubtitle>{copy.subtitle}</HeroSubtitle>
          <LinkCta
            href={copy.titleCtaHref}
            label={copy.titleCtaText}
            color="proBrand"
            hoverColor="white"
          />
          <CoolVisual />
        </div>
      </Background>
      <Background color="white">
        <SectionHeading align="center">{copy.whyWhnsTilte}</SectionHeading>
        <WhyWhnsContent>
          <GarnishedInfoText align="center" spacing={theme.spacing(4)}>
            <Large weight="medium" align="center" css={{ fontFamily: 'Roboto Mono' }}>
              {copy.whyWhns1}
            </Large>
          </GarnishedInfoText>
          <GarnishedInfoText align="center" spacing={theme.spacing(4)}>
            <Large weight="medium" align="center" css={{ fontFamily: 'Roboto Mono' }}>
              {copy.whyWhns2}
            </Large>
          </GarnishedInfoText>
        </WhyWhnsContent>
        <LinkCta
          href={copy.learnMoreCtaHref}
          label={copy.learnMoreCtaText}
          color="brandDark"
          hoverColor="proBrand"
        />
      </Background>
      <Background color="burntUmber">
        <HowItWorksContent>
          <div css={{ width: 'max-content', maxWidth: '100%', margin: '0 auto' }}>
            <SectionHeading colored="white">{copy.howItWorksTitle}</SectionHeading>
            <div css={{ display: 'flex' }}>
              <GarnishedInfoText
                align="flex-start"
                spacing={theme.spacing(3)}
                Heading={
                  <Large
                    colored="grey20"
                    children="Receiving"
                    css={{ fontFamily: 'Roboto Mono' }}
                  />
                }>
                <Regular colored="white">
                  {documentToReactComponents(copy.howItWorksReceiving.json)}
                </Regular>
                <TutorialLink href={copy.receivingTutorialHref} />
              </GarnishedInfoText>
              <GarnishedInfoText
                align="flex-start"
                spacing={theme.spacing(3)}
                Heading={
                  <Large colored="grey20" children="Wrapping" css={{ fontFamily: 'Roboto Mono' }} />
                }>
                <Regular colored="white">
                  {documentToReactComponents(copy.howItWorksWrapping.json)}
                </Regular>
              </GarnishedInfoText>
              <GarnishedInfoText
                align="flex-start"
                spacing={theme.spacing(3)}
                Heading={
                  <Large
                    colored="grey20"
                    children="Unwrapping"
                    css={{ fontFamily: 'Roboto Mono' }}
                  />
                }>
                <Regular colored="white">
                  {documentToReactComponents(copy.howItWorksUnwrapping.json)}
                </Regular>
              </GarnishedInfoText>
            </div>
          </div>
        </HowItWorksContent>
        <div css={{ marginTop: theme.spacing(12), marginBottom: theme.spacing(2) }}>
          <LinkCta
            href={copy.faqHref}
            label="Frequently asked questions"
            color="proBrand"
            hoverColor="white"
          />
        </div>
      </Background>
    </Layout>
  );
};

export default WrappedHnsPage;
