import * as React from 'react';
import styled, { css } from 'styled-components';

import { theme } from '../../theme';
import { Link } from '../LandingPage/Link';
import { DisplayLarge, HeaderSmall, Small } from '../text';
import EthereumLogo from './EthereumLogoSvg';
import HandshakeLogo from './HandshakeLogoSvg';

const inlineAnchorStylesCss = css`
  & p > a {
    color: ${({ theme }) => theme.color.proBrand};
    transition: ${({ theme }) => theme.transitions.color};

    &:hover {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

const Background = styled.div`
  background: ${({ theme, color }) => theme.color[color]};
  padding-top: ${({ theme }) => theme.spacing(14)};
  padding-bottom: ${({ theme }) => theme.spacing(15)};
  ${inlineAnchorStylesCss}
`;

const HeroTitle = styled(DisplayLarge).attrs(props => ({ align: 'center', colored: 'white' }))`
  font-size: 72px;
  line-height: 80px;
  font-family: 'Roboto Variable';
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(6)};
  font-variation-settings: 'wght' 300;

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 48px;
    line-height: 56px;
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const HeroSubtitle = styled(HeaderSmall).attrs(props => ({
  as: 'h2',
  align: 'center',
  colored: 'grey20',
}))`
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.huge.size};
    line-height: ${({ theme }) => theme.text.huge.lineHeight};
  }
`;

const LinkCta = styled(Link)`
  width: max-content;
  margin: 0 auto;

  & * {
    color: ${({ theme, color }) => theme.color[color]};
    stroke: ${({ theme, color }) => theme.color[color]};
  }

  &:hover * {
    color: ${({ theme, hoverColor }) => theme.color[hoverColor]};
    stroke: ${({ theme, hoverColor }) => theme.color[hoverColor]};
  }
`;

const CoolVisual = styled.figure.attrs(props => ({
  children: (
    <React.Fragment>
      <HandshakeLogo />
      <DottedLine />
      <EthereumLogo />
    </React.Fragment>
  ),
}))`
  width: ${({ theme }) => theme.spacing(87)};
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(5)};
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(6)};
  justify-content: space-between;

  .handshakeLogo {
    width: 138px;
  }
  .ethereumLogo {
    width: 82px;
    height: 132px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: ${({ theme }) => theme.spacing(70)};

    .handshakeLogo {
      width: 120px;
    }
    .ethereumLogo {
      width: 70px;
      height: 114px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: ${({ theme }) => theme.spacing(40)};
    margin-top: ${({ theme }) => theme.spacing(6)};
    margin-right: ${({ theme }) => theme.spacing(5)};

    .handshakeLogo {
      width: 82px;
    }
    .ethereumLogo {
      width: 48px;
      height: 78px;
    }
  }
`;

const DottedLine = styled.div`
  flex: 1;
  height: 1px;
  align-self: center;
  margin-left: ${({ theme }) => theme.spacing(6)};
  margin-right: ${({ theme }) => theme.spacing(5)};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.color.white} 30%,
    transparent 0
  );
  background-size: 20px 10px;
  background-repeat: repeat-x;

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-left: ${({ theme }) => theme.spacing(4)};
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`;

const SectionHeading = styled(HeaderSmall).attrs(props => ({ as: 'h3' }))`
  font-size: 56px;
  line-height: 64px;
  font-family: 'Roboto Variable';
  margin-bottom: ${({ theme }) => theme.spacing(13)};
  font-variation-settings: 'wght' 300;

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

const GarnishedInfoText = ({ children, align, spacing, Heading }) => (
  <div
    className="garnishedInfoText"
    css={{ display: 'flex;', flexDirection: 'column', alignItems: align }}>
    {Heading}
    <div
      css={{
        width: theme.spacing(5),
        height: '2px',
        background: theme.color.proBrand,
        marginBottom: spacing,
        marginTop: Boolean(Heading) ? theme.spacing(3) : 0,
      }}
    />
    {children}
  </div>
);

const WhyWhnsContent = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 1070px;
  padding-bottom: ${({ theme }) => theme.spacing(8)};
  justify-content: space-between;

  .garnishedInfoText {
    max-width: ${({ theme }) => theme.spacing(60)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: center;
    flex-direction: column;

    .garnishedInfoText {
      max-width: 787px;
    }

    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(10)};
    }
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    .garnishedInfoText {
      margin-left: ${({ theme }) => theme.spacing(11)};
      margin-right: ${({ theme }) => theme.spacing(11)};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    .garnishedInfoText {
      margin-left: ${({ theme }) => theme.spacing(6)};
      margin-right: ${({ theme }) => theme.spacing(6)};
    }
  }
`;

const HowItWorksContent = styled.div`
  margin: 0 auto;

  @media ${({ theme }) => theme.breakpoints.lg} {
    .garnishedInfoText {
      max-width: 330px;
    }

    & > div > div:last-of-type > * + * {
      margin-left: ${({ theme }) => theme.spacing(11)};
    }
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-left: ${({ theme }) => theme.spacing(6)};

    & > div > div:last-of-type {
      flex-direction: column;
    }

    .garnishedInfoText {
      max-width: 80%;
    }

    & > div > div:last-of-type > * + * {
      margin-top: ${({ theme }) => theme.spacing(10)};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-left: ${({ theme }) => theme.spacing(3)};

    .garnishedInfoText {
      max-width: 90%;
    }
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};

    .garnishedInfoText {
      max-width: 100%;
    }
  }
`;

const TutorialLink = styled(Small).attrs(props => ({
  as: 'a',
  weight: 'medium',
  colored: 'proBrand',
  children: 'View tutorial',
}))`
  margin-top: ${({ theme }) => theme.spacing(3)};
  transition: ${({ theme }) => theme.transitions.color};

  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`;

export {
  Background,
  HeroTitle,
  HeroSubtitle,
  LinkCta,
  CoolVisual,
  SectionHeading,
  GarnishedInfoText,
  WhyWhnsContent,
  HowItWorksContent,
  TutorialLink
};
