import React from 'react';

const HandshakeLogo = () => (
  <svg className="handshakeLogo" viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M65.8216 107.904C65.4822 107.347 65.0413 106.622 64.5612 105.834C62.9469 103.175 60.8786 99.7714 60.6451 99.3983C60.5668 99.2719 60.5305 98.9641 60.6944 98.6708C61.0686 98 68.135 85.2992 69.8566 82.2035L80.1748 82.1904L65.8216 107.904Z"
      fill="white"
    />
    <path
      d="M41.3436 91.8161C38.7329 91.8161 36.1367 91.8146 35.7552 91.8146H35.7538C35.4985 91.8146 35.1896 91.636 35.0489 91.4066L33.3795 88.6608C32.1901 86.7035 30.673 84.2075 29.8318 82.8267H40.5154L45.586 91.8175C44.4794 91.8175 42.9129 91.8161 41.3436 91.8161Z"
      fill="white"
    />
    <path
      d="M58.6892 28.3764C58.9271 27.9495 59.2345 27.9495 59.3346 27.9495L69.4279 27.9509L53.7723 55.9211C53.7593 55.9443 53.752 55.969 53.7404 55.9937C53.7172 56.0401 53.6969 56.0881 53.6781 56.1374C53.6621 56.1854 53.6476 56.2318 53.6346 56.2797C53.6229 56.3247 53.6128 56.3698 53.6055 56.4148C53.5968 56.4714 53.591 56.5266 53.5896 56.5818C53.5881 56.6064 53.5823 56.6297 53.5823 56.6558C53.5823 56.6747 53.5867 56.6921 53.5881 56.711C53.5896 56.7662 53.5954 56.8199 53.6041 56.8736C53.6099 56.9186 53.6171 56.9636 53.6287 57.0086C53.6404 57.0566 53.6563 57.1016 53.6723 57.148C53.6882 57.193 53.7042 57.2395 53.7245 57.2831C53.7448 57.3266 53.768 57.3673 53.7926 57.4094C53.8173 57.4501 53.8405 57.4907 53.8695 57.5299C53.8971 57.5691 53.929 57.6054 53.9623 57.6432C53.9943 57.678 54.0262 57.7143 54.061 57.7477C54.0958 57.7797 54.132 57.8073 54.1697 57.8363C54.2118 57.8697 54.2539 57.9002 54.2988 57.9278C54.3162 57.9379 54.3293 57.951 54.3467 57.9612C54.3641 57.9713 54.383 57.9742 54.4018 57.9829C54.4787 58.0222 54.5599 58.0555 54.6455 58.0817C54.6759 58.0904 54.7049 58.102 54.7369 58.1093C54.8456 58.1354 54.9573 58.1514 55.0748 58.1514C55.0762 58.1514 55.0763 58.1528 55.0777 58.1528H55.0791H55.0806L55.7434 58.1514C55.7463 58.1514 55.7492 58.1528 55.7521 58.1528H55.7536L81.6954 58.118C82.3815 59.3086 83.6636 61.5491 84.7558 63.457C85.5941 64.9236 86.2874 66.1331 86.8327 67.0842H48.2289C48.2115 67.0842 48.1956 67.09 48.1796 67.0914C47.6546 67.1002 47.1484 67.3775 46.8815 67.8755L40.491 79.8285H29.951C34.5517 71.5898 58.3817 28.9252 58.6892 28.3764Z"
      fill="white"
    />
    <path
      d="M97.2989 54.5158L92.2268 45.5249L102.053 45.5278C102.316 45.5278 102.622 45.702 102.765 45.9373C102.922 46.1943 103.72 47.5069 104.678 49.0838C105.826 50.9714 107.198 53.2278 107.983 54.5158H97.2989Z"
      fill="#D0D0D0"
    />
    <path
      d="M79.122 108.968C78.8842 109.394 78.5752 109.394 78.4752 109.394H74.4605C72.3356 109.392 69.8844 109.391 68.4253 109.391L84.0389 81.4206C84.2985 80.956 84.2927 80.3897 84.0229 79.9308C83.7532 79.472 83.2629 79.1903 82.7321 79.1903H82.7306L56.1202 79.2252L50.8291 70.0833H89.5881C89.591 70.0833 89.5939 70.0818 89.5983 70.0818C89.6026 70.0818 89.6055 70.0833 89.6084 70.0833C89.6302 70.0833 89.6505 70.076 89.6722 70.0746C89.7593 70.0688 89.8463 70.06 89.929 70.0397C89.9928 70.0252 90.0552 70.0049 90.1175 69.9816C90.1509 69.9686 90.1828 69.9555 90.2147 69.941C90.5048 69.8118 90.7615 69.5998 90.9254 69.2992L97.3144 57.5132H107.862L79.122 108.968Z"
      fill="#D0D0D0"
    />
    <path
      d="M48.2297 90.4004L43.1011 81.3079L48.2703 71.6389L53.4555 80.5964C51.8441 83.6239 49.3291 88.3458 48.2297 90.4004Z"
      fill="#D0D0D0"
    />
    <path
      d="M72.0082 29.4722L72.9452 31.0113C74.5914 33.7208 76.9164 37.5454 77.1629 37.9432C77.2413 38.0695 77.2775 38.3759 77.1122 38.6707L67.95 55.138L57.6362 55.151L72.0082 29.4722Z"
      fill="#D0D0D0"
    />
    <path
      d="M89.5784 46.9316L94.7099 56.0285L89.4783 65.6772C88.8546 64.5911 88.0801 63.2378 87.3535 61.9673C85.9176 59.4567 84.8704 57.6286 84.2801 56.6064C85.3954 54.5692 88.3441 49.1794 89.5784 46.9316Z"
      fill="white"
    />
    <circle cx="69" cy="69" r="67.5" stroke="#D0D0D0" strokeWidth="3" />
  </svg>
);

export default HandshakeLogo;